<template>
  <v-container fluid class="px-4 pt-0">
    <v-row class="py-3">
      <v-col cols="6" class="pb-0">
        <v-tabs v-model="selectedTab" @change="changeTab">
          <v-tab> transactions </v-tab>
          <v-tab> current levels </v-tab>
          <v-tab v-if="enabled_inventory_tag == 1"> inventory tags </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="4" class="pb-0">
        <v-row>
          <v-col class="pa-0 d-flex align-end">
            <v-text-field
              v-model="search"
              append-icon="fas fa-search"
              label="Search by name or code"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
           <template v-if="selectedTab === 4">
            <v-col class="pa-0 d-flex justify-end">
              <v-menu
                v-model="fromMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="date-filter"
                    v-model="tableFilters.from"
                    label="From"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="tableFilters.from"
                  @input="fromMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="pa-0 d-flex justify-start"
              ><v-menu
                v-model="toMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="date-filter ml-6"
                    v-model="tableFilters.to"
                    label="To"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="tableFilters.to"
                  @input="toMenu = false"
                ></v-date-picker> </v-menu
            ></v-col> 
          </template> 
          <v-spacer v-else></v-spacer> 
        </v-row>
      </v-col>
      <v-col cols="2" class="pb-0">
        <v-row fill-height>
          <v-col class="d-flex justify-end align-end">
            <v-btn class="mr-2" color="primary" outlined @click="generatePdf">
              pdf
            </v-btn>
            <v-btn color="primary" outlined @click="generateExcel">
              excel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Transactions v-if="selectedTab === 0" ref="transactions"></Transactions>
    <CurrentLevels v-if="selectedTab === 1" ref="currentLevels"></CurrentLevels>
    <InventoryTags v-if="selectedTab === 2" ref="inventory"></InventoryTags>
    
  </v-container>
</template>

<script>
import Transactions from '@/components/Inventory/Transactions';
import CurrentLevels from '@/components/Inventory/CurrentLevels';
import InventoryTags from '@/components/Inventory/InventoryTags';

export default {
  components: {
    Transactions,
    CurrentLevels,
    InventoryTags
  },
  data: () => ({
    selectedTab: 0,
    fromMenu: false,
    toMenu: false,
    search: '',
    tableFilters: {
      from: null,
      to: null,
    },
    enabled_inventory_tag: 0
  }),
  watch: {
    tableFilters: {
      deep: true,
      handler() {
        this.updateFilters();
      },
    },
    search: {
      handler() {
        this.updateSearch();
      },
    },
  },
  created() {
    this.initialize();
    this.fetchBusinessRules();
  },
  methods: {
    async initialize() {
      const selectedTab = this.$store.getters.selectedInventoryTab;
      if (selectedTab) {
        this.selectedTab = selectedTab;
      }
    },
    changeTab() {
      // Keep current tab in local storage
      this.$store.commit('storeInventoryTab', this.selectedTab);
    },
    generatePdf() {
      if (this.selectedTab === 1) {
        this.$refs.currentLevels.generatePdf(this.search);
      } else if (this.selectedTab === 2) {
        this.$refs.inventory.generatePdf();
      }else if (this.selectedTab === 0){
       this.$refs.transactions.generatePdf();
      }
    },
    generateExcel() {
      if (this.selectedTab === 1) {
        this.$refs.currentLevels.generateExcel(this.search);
      } else if (this.selectedTab === 2) {
        this.$refs.inventory.generateExcel();
      }else if(this.selectedTab === 0){
        this.$refs.transactions.generateExcel();
      }
    },
    updateFilters() {
      if (this.selectedTab === 1) {
        this.$refs.currentLevels.updateFilters(this.tableFilters);
      } else if (this.selectedTab === 0) {
        this.$refs.transactions.updateFilters(this.tableFilters);
      }
    },
    updateSearch() {
      if (this.selectedTab === 1) {
        this.$refs.currentLevels.updateSearch(this.search);
      } else if (this.selectedTab === 2) {
        this.$refs.inventory.updateSearch(this.search);
      } else if (this.selectedTab === 0) {
        this.$refs.transactions.updateSearch(this.search);
      }
    },
    async fetchBusinessRules(){

      const businessRules = this.$store.getters.businessRules;

      var enabled_inventory_tag = businessRules.find(x => x.name === 'Inventory tags');
      this.enabled_inventory_tag = enabled_inventory_tag != null ? enabled_inventory_tag.value : 0;
       
      
    }
  },
};
</script>

<style lang="scss" scoped>
.date-filter {
  display: flex;
  flex: 0 1 10rem;
}
</style>
