<template>
  <v-container class="pa-0" fluid>
    <!-- <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search by item name or code"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="4" class="d-flex justify-center">
        <div class="headline d-flex align-self-end">
          Viana Warehouse
        </div>
      </v-col>
      <v-col cols="4" class="d-flex justify-end">
        <v-btn
          class="d-flex align-self-end mr-2"
          color="primary"
          outlined
          @click="generatePdf"
        >
          pdf
        </v-btn>
        <v-btn
          class="d-flex align-self-end"
          color="primary"
          outlined
          @click="generateExcel"
        >
          excel
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          id="levels-table"
          :headers="levelsHeaders"
          :items="levels"
          :footer-props="levelsTableFooterProps"
          :items-per-page="levelsPerPage"
          :search="search"
          :loading="loading"
          dense
        >
          <template v-slot:item="{ item }">
            <tr>
              <td v-if="enabled_unique_item_code==1">
                <TextHighlight :queries="search">
                  {{ item.unique_code ? item.unique_code : '-' }}
                </TextHighlight>
              </td>
               <td v-if="enabled_material_id==1">
                <TextHighlight :queries="search">
                  {{ item.material_id ? item.material_id : '-' }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.item_name ? item.item_name : '-' }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.item_category }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                {{ item.manufacturer_name ? item.manufacturer_name : '-' }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                {{
                  item.material_property_name
                    ? item.material_property_name
                    : '-'
                }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">{{ item.length_name ? item.length_name : '-' }}</TextHighlight></td>
              <td>
                <TextHighlight :queries="search">{{ item.color_code ? item.color_code : '-' }}: {{ item.color_name ? item.color_name : '-' }}</TextHighlight></td>
              <td>
                <TextHighlight :queries="search">{{ item.finishing_name ? item.finishing_name : '-' }}</TextHighlight></td>
              <td>
                {{ item.total_quantity.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }).replace('$','').split('.')[0] }}
              </td>
              <td>{{ item.uom_name }}</td>
              <td class="pl-2">
                <v-tooltip top v-if="item.total_quantity < item.danger_level">
                  <template v-slot:activator="{ on }">
                    <v-icon color="red" dark v-on="on" class="py-1"
                      >fas fa-exclamation-circle</v-icon
                    >
                  </template>
                  <span
                    >DANGER: Quantity is lower than
                    {{ item.danger_level }}</span
                  >
                </v-tooltip>
                <v-tooltip
                  top
                  v-else-if="item.total_quantity < item.caution_level"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon color="orange" dark v-on="on" class="py-1"
                      >fas fa-exclamation-circle</v-icon
                    >
                  </template>
                  <span
                    >CAUTION: Quantity is lower than
                    {{ item.caution_level }}</span
                  >
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import TransactionsApi from '../../api/TransactionsApi';
import ModulesApi from '../../api/ModulesApi';
import ExcelHelper from '../../helpers/ExcelHelper';
import PdfHelper from '../../helpers/PdfHelper';
import * as FormatHelpers from '../../helpers/FormatHelpers';

export default {
  name: 'CurrentLevels',
  components: {
    TextHighlight,
  },
  computed: {
    levelsHeaders(){
      var levelsHeaders = [];
      if(this.enabled_unique_item_code == 1)
        levelsHeaders.push({ text: 'Unique Item Code', value: 'unique_code', class: 'unique_item_code' });
      if(this.enabled_material_id == 1)
        levelsHeaders.push({ text: 'Material ID', value: 'material_id', class: 'material_id' });
      levelsHeaders.push({ text: 'Item Name', value: 'name' });
      levelsHeaders.push({ text: 'Category', value: 'category' });
      levelsHeaders.push({ text: 'Manufacturer', value: 'manufacturer', filterable: false });
      levelsHeaders.push({
        text: 'Material Property',
        value: 'material_property',
        filterable: false,
      });
      levelsHeaders.push({ text: 'Length', value: 'length', filterable: false });
      levelsHeaders.push({ text: 'Colour', value: 'color', filterable: false });
      levelsHeaders.push({ text: 'Finishing', value: 'finishing', filterable: false });
      levelsHeaders.push({ text: 'Quantity',value: 'quantity' });
      levelsHeaders.push({ text: 'UOM', value: 'uom'});
      levelsHeaders.push({ text: ''});
      return levelsHeaders;
    }
  },
  data: () => ({
    // Page
    search: '',
    loading: true,
    levels: [],
    // levelsHeaders: [
    //   { text: 'Unique Item Code', value: 'unique_code', class: 'unique_item_code' },
    //   { text: 'Material ID', value: 'material_id', class: 'material_id' },
    //   { text: 'Item Name', value: 'name' },
    //   { text: 'Category', value: 'category' },
    //   { text: 'Manufacturer', value: 'manufacturer', filterable: false },
    //   {
    //     text: 'Material Property',
    //     value: 'material_property',
    //     filterable: false,
    //   },
    //   { text: 'Length', value: 'length', filterable: false },
    //   { text: 'Colour', value: 'color', filterable: false },
    //   { text: 'Finishing', value: 'finishing', filterable: false },
    //   { text: 'Quantity',value: 'quantity' },
    //   { text: 'UOM', value: 'uom', align: 'end' },
    // ],
    levelsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    levelsPerPage: 20,
    enabled_material_id: null,
    enabled_unique_item_code: null,
    enabled_inventory_tag: null
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await Promise.all([this.fetchLevels(), this.fetchBusinessRules()]);
    },
    updateSearch(search) {
      this.search = search;
    },
    generateExcel() {
      const excelData = this.levels.map((el) => {
        return {
          'Unique Item Code': el.unique_code,
          'Die #': el.material_id,
          'Item Name': el.item_name,
          'Manufacturer': el.manufacturer,
          'Material Property': el.material_property,
          'Length': el.length,
          'Color': el.color_code ? el.color_code : el.color_name,
          'Finishing': el.finishing,
          'Quantity': el.total_quantity,
          'UOM': el.uom,
        };
      });
      let excelWorkbook = ExcelHelper.writeJson(excelData, 'Current Levels');
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Current-Levels_${now}`;
      ExcelHelper.saveExcelToBrowser(excelWorkbook, fileName);
    },
    async generatePdf() {
      this.levelsPerPage = -1;
      await this.$nextTick();
      const pdf = PdfHelper.generateFromTable(
        '#levels-table > div > table',
        'landscape',
        'Current Inventory Levels',
        'current-levels'
      );
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Current-Levels_${now}`;
      PdfHelper.savePdfToBrowser(pdf, fileName);
    },
    async fetchLevels() {
      this.loading = true;
      this.levels = await TransactionsApi.getCurrentLevels();
      this.loading = false;
    },
    // async fetchModules() {
    //   this.modules = await ModulesApi.getList();
    //   this.enabled_material_id = this.modules[5].enabled;
    //   this.enabled_unique_item_code = this.modules[6].enabled;
    //   if(this.enabled_material_id == 0) {
    //     document.getElementsByClassName('material_id')[0].style.display = 'none';
    //   } 
    //   if(this.enabled_unique_item_code == 0) {
    //     document.getElementsByClassName('unique_item_code')[0].style.display = 'none';
    //   } 
    // },
    async fetchBusinessRules(){

      const businessRules = this.$store.getters.businessRules;

      var enabled_material_id = businessRules.find(x => x.name === 'Material Id');
      this.enabled_material_id = enabled_material_id != null ? enabled_material_id.value : 0;
      
      var enabled_unique_item_code = businessRules.find(x => x.name === 'Unique Item Code');
      this.enabled_unique_item_code = enabled_unique_item_code != null ? enabled_unique_item_code.value : 0;  
      
    }
  },
};
</script>
<style scoped></style>
