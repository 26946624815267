<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <!-- <v-col cols="2">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search by name or code"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="d-flex justify-center">
        <div class="headline d-flex align-self-end">
          Viana Warehouse
        </div>
      </v-col> -->
      <v-col
        cols="3"
        class="py-0"
      >
        <v-autocomplete
          v-model="filterClientId"
          label="Address"
          :items="filterClients"
          item-text="address"
          item-value="id"
          hide-details="true"
          clearable
          @change="fetchTransactions"
        />
      </v-col>
      <v-col class="py-0">
        <v-autocomplete
          v-model="filterItemId"
          label="Item Name"
          :items="filterItems"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
          @change="fetchTransactions"
        />
      </v-col>
      <v-col class="py-0">
        <v-autocomplete
          v-model="filterManufacturerId"
          label="Manufacturer"
          :items="filterManufacturers"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
          @change="fetchTransactions"
        />
      </v-col>
      <v-col class="py-0">
        <v-autocomplete
          v-model="filterMaterialPropertyId"
          label="Material Property"
          :items="filterMaterialProperties"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
          @change="fetchTransactions"
        />
      </v-col>
      <v-col class="py-0">
        <v-autocomplete
          v-model="filterLengthId"
          label="Length"
          :items="filterLengths"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
          @change="fetchTransactions"
        />
      </v-col>
      <v-col class="py-0">
        <v-autocomplete
          v-model="filterColorId"
          label="Colour"
          :items="filterColors"
          item-text="code"
          item-value="id"
          hide-details="true"
          clearable
          @change="fetchTransactions"
        >
          <template #selection="{ item }">
            <span class="black--text">
              {{ item.code }}: {{ item.name }}
            </span>
          </template>
          <template #item="{ item }">
            <span class="black--text">
              {{ item.code }}: {{ item.name }}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="py-0">
        <v-autocomplete
          v-model="filterFinishingId"
          label="Finishing"
          :items="filterFinishings"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
          @change="fetchTransactions"
        />
      </v-col>
      <v-col class="py-0">
        <v-autocomplete
          v-model="filterUomId"
          label="UOM"
          :items="filterUoms"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
          @change="fetchTransactions"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          id="transactions-table"
          class="elevation-1"
          :headers="transactionsHeaders"
          :items="transactions"
          :footer-props="transactionsTableFooterProps"
          :items-per-page="transactionsPerPage"
          :search="search"
          :loading="loading"
          dense
        >
          <template #item="{ item }">
            <tr
              :class="{
                green: item.is_rma || item.is_return,
                red: !item.is_rma || !item.is_return,
                'lighten-5': true,
              }"
            >
              <!-- <td>{{ item.id }}</td> -->
              <td v-if="enabled_bom_rma == 1">
                {{ item.bom_rma }}
              </td>
              <td v-if="enabled_unique_item_code==1">
                <TextHighlight :queries="search">
                  {{ item.unique_code ? item.unique_code : '-' }}
                </TextHighlight>
              </td>
              <td v-if="enabled_type == 1">
                <v-chip
                  color="#bf0000"
                  text-color="white"
                  v-if="item.transaction_type === 'Supply'"
                >
                  {{ item.transaction_type }}
                </v-chip>
                <v-chip
                  color="#306844"
                  text-color="white"
                  v-else
                >
                  {{ item.transaction_type }}
                </v-chip>
              </td>
              <td v-if="enabled_address == 1">
                <TextHighlight :queries="search">
                  {{ item.address }}
                </TextHighlight>
              </td>
              <td v-if="enabled_material_id==1">
                <TextHighlight :queries="search">
                  {{ item.item_material_id ? item.item_material_id : '-' }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.item_name ? item.item_name : '-' }}
                </TextHighlight>
              </td>
              <td v-if="enabled_inventory_tag==1">
                <TextHighlight :queries="search">
                  {{ item.inventory_tag ? item.inventory_tag : '-' }}
                </TextHighlight>
              </td>
              <td>
                {{ item.manufacturer_name ? item.manufacturer_name : '-' }}
              </td>
              <td>
                {{
                  item.material_property_name
                    ? item.material_property_name
                    : '-'
                }}
              </td>
              <td>{{ item.length_name ? item.length_name : '-' }}</td>
              <td>{{ item.color_code ? item.color_code : ': ' }} {{ item.color_name ? item.color_name : '' }}</td>
              <td>{{ item.finishing_name ? item.finishing_name : '-' }}</td>
              <td>{{ item.uom_name }}</td>
              <td v-if="enabled_bom_rma == 1">
                <template v-if="!item.is_rma">
                  -
                </template>{{ item.quantity }}
              </td>
              <td v-else>
                <template v-if="!item.is_return">
                  -
                </template>{{ item.quantity }}
              </td>
              <td v-if="enabled_missing==1">
                {{ item.missing == '0' ? '-' : item.missing }}
              </td>
              <td class="text-right">
                {{ item.created_at }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import TransactionsApi from '../../api/TransactionsApi';
import InventoryItemsApi from '../../api/InventoryItemsApi';
import ColorsApi from '../../api/ColorsApi';
import FinishingsApi from '../../api/FinishingsApi';
import ManufacturersApi from '../../api/ManufacturersApi';
import ModulesApi from '../../api/ModulesApi';
import InventoryCategoriesApi from '../../api/InventoryCategoriesApi';
import UomsApi from '../../api/UomsApi';
import MaterialPropertiesApi from '../../api/MaterialPropertiesApi';
import LengthsApi from '../../api/LengthsApi';
import ClientsApi from '../../api/ClientsApi';
import PdfHelper from '../../helpers/PdfHelper';
import ExcelHelper from '../../helpers/ExcelHelper';
import * as FormatHelpers from '../../helpers/FormatHelpers';
import BusinessRulesApi from '../../api/BusinessRulesApi';

export default {
  name: 'Transactions',
  components: {
    TextHighlight,
  },
  data: () => ({
    // Page
    search: '',
    loading: true,
    transactions: [],
    
    transactionsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    transactionsPerPage: 20,
    filterItems: [],
    filterManufacturers: [],
    filterMaterialProperties: [],
    filterLengths: [],
    filterColors: [],
    filterFinishings: [],
    filterUoms: [],
    filterClients: [],
    filterItemId: null,
    filterManufacturerId: null,
    filterMaterialPropertyId: null,
    filterLengthId: null,
    filterColorId: null,
    filterFinishingId: null,
    filterUomId: null,
    filterQuantity: null,
    filterFrom: null,
    filterTo: null,
    filterClientId: null,
    enabled_material_id: null,
    enabled_unique_item_code: null,
    enabled_inventory_tag: null,
    enabled_address: null,
    enabled_type: null,
    enabled_missing: null,
    enabled_bom_rma: null
  }),
  computed: {
    filters() {
      return {
        itemId: this.filterItemId,
        manufacturerId: this.filterManufacturerId,
        materialPropertyId: this.filterMaterialPropertyId,
        lengthId: this.filterLengthId,
        colorId: this.filterColorId,
        finishingId: this.filterFinishingId,
        uomId: this.filterUomId,
        from: this.filterFrom,
        to: this.filterTo,
        clientId: this.filterClientId,
      };
    },
    transactionsHeaders(){
      var transactionsHeaders = [];

      if(this.enabled_bom_rma == 1)
        transactionsHeaders.push({ text: 'BOM/RMA Code', value: 'bom_rma', filterable: false });
      if(this.enabled_unique_item_code == 1)
        transactionsHeaders.push({ text: 'Unique Item Code', value: 'unique_code', class: 'unique_item_code' });
      if(this.enabled_type == 1)
        transactionsHeaders.push({ text: 'Type', value: 'transaction_type', filterable: false });
      if(this.enabled_address == 1)
        transactionsHeaders.push({ text: 'Address', value: 'address_simple', filterable: false });
      if(this.enabled_material_id == 1)
        transactionsHeaders.push({ text: 'Material ID', value: 'item_material_id', class: 'material_id' });
      transactionsHeaders.push({ text: 'Item Name', value: 'item_name' });
      if(this.enabled_inventory_tag == 1)
        transactionsHeaders.push({ text: 'Inventory Tag', value: 'inventory_tag' });
      transactionsHeaders.push({ text: 'Manufacturer', value: 'manufacturer_name', filterable: false });
      transactionsHeaders.push({
          text: 'Material Property',
          value: 'material_property_name',
          filterable: false,
        });
      transactionsHeaders.push({ text: 'Length', value: 'length_name', filterable: false });
      transactionsHeaders.push({ text: 'Colour', value: 'color_name', filterable: false });
      transactionsHeaders.push({ text: 'Finishing', value: 'finishing_name', filterable: false });
      transactionsHeaders.push({ text: 'UOM', value: 'uom_name', filterable: false });
      transactionsHeaders.push({ text: 'Quantity', value: 'quantity', filterable: false });
      if(this.enabled_missing == 1)
        transactionsHeaders.push({ text: 'Missing', value: 'missing', filterable: false });
      transactionsHeaders.push({ text: 'Created At', value: 'created_at', align: 'right', filterable: false });
      return transactionsHeaders;
    }

  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.fetchTransactions();
      this.fetchItems();
      this.fetchManufacturers();
      this.fetchUoms();
      this.fetchColors();
      this.fetchFinishings();
      this.fetchMaterialProperties();
      this.fetchLengths();
      this.fetchClients();
      //this.fetchModules();
      this.fetchBusinessRules();
    },
    async fetchTransactions() {
      this.loading = true;
      this.transactions = await TransactionsApi.getList(this.filters);
      this.loading = false;
    },
    async fetchItems() {
      this.filterItems = await InventoryItemsApi.getList();
    },
    async fetchManufacturers() {
      this.filterManufacturers = await ManufacturersApi.getList();
    },
    async fetchUoms() {
      this.filterUoms = await UomsApi.getList();
    },
    async fetchColors() {
      this.filterColors = await ColorsApi.getList();
    },
    async fetchFinishings() {
      this.filterFinishings = await FinishingsApi.getList();
    },
    async fetchMaterialProperties() {
      this.filterMaterialProperties = await MaterialPropertiesApi.getList();
    },
    async fetchLengths() {
      this.filterLengths = await LengthsApi.getList();
    },
    async fetchClients() {
      this.filterClients = await ClientsApi.getList();
    },
    // async fetchModules() {
    //   this.modules = await ModulesApi.getList();
    //   //this.enabled_material_id = this.modules[5].enabled;
    //   this.enabled_unique_item_code = this.modules[6].enabled;
    //   // if(this.enabled_material_id == 0) {
    //   //   document.getElementsByClassName('material_id')[0].style.display = 'none';
    //   // } 
    //   if(this.enabled_unique_item_code == 0) {
    //     document.getElementsByClassName('unique_item_code')[0].style.display = 'none';
    //   } 
    // },
    updateSearch(search) {
      this.search = search;
    },
    updateFilters(filters) {
      this.filterFrom = filters.from;
      this.filterTo = filters.to;
      this.fetchTransactions();
    },
    generateExcel() {
      const excelData = this.transactions.map((el) => {
        return {
          'BOM/RMA Code': el.bom_rma,
          'Type': el.transaction_type,
          'Unique Item Code': el.unique_code,
          'Material Id': el.material_id,
          'Item Name': el.item_name,
          'Inventory Tag': el.inventory_tag,
          'Manufacturer': el.manufacturer_name,
          'Material Property': el.material_property_name,
          'Length': el.length_name,
          'Color': el.color_name,
          'Finishing': el.finishing_name,
          'UOM': el.uom_name,
          'Quantity': el.quantity,
          'Missing': el.missing,
          'Created At': el.created_at,
        };
      });
      let excelWorkbook = ExcelHelper.writeJson(excelData, 'Transactions');
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Transactions_${now}`;
      ExcelHelper.saveExcelToBrowser(excelWorkbook, fileName);
    },
    async generatePdf() {
      this.transactionsPerPage = -1;
      await this.$nextTick();
      const pdf = PdfHelper.generateFromTable(
        '#transactions-table > div > table',
        'landscape',
        'Transactions',
        'transactions'
      );
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Transactions_${now}`;
      PdfHelper.savePdfToBrowser(pdf, fileName);
    },
    async fetchBusinessRules(){

      const businessRules = this.$store.getters.businessRules;

      var enabled_inventory_tag = businessRules.find(x => x.name === 'Inventory tags');
      this.enabled_inventory_tag = enabled_inventory_tag != null ? enabled_inventory_tag.value : 0;

      var enabled_material_id = businessRules.find(x => x.name === 'Material Id');
      this.enabled_material_id = enabled_material_id != null ? enabled_material_id.value : 0;

      var enabled_address = businessRules.find(x => x.name === 'Address');
      this.enabled_address = enabled_address != null ? enabled_address.value : 0;

      var enabled_type = businessRules.find(x => x.name === 'Transaction Type');
      this.enabled_type = enabled_type != null ? enabled_type.value : 0;

      var enabled_missing = businessRules.find(x => x.name === 'Missing Items');
      this.enabled_missing = enabled_missing != null ? enabled_missing.value : 0;    

      var enabled_bom_rma = businessRules.find(x => x.name === 'Bom/rma code');
      this.enabled_bom_rma = enabled_bom_rma != null ? enabled_bom_rma.value : 0;   
      
      var enabled_unique_item_code = businessRules.find(x => x.name === 'Unique Item Code');
      this.enabled_unique_item_code = enabled_unique_item_code != null ? enabled_unique_item_code.value : 0;  

    }
  },
};
</script>
<style scoped></style>
