<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          id="inventory-tags-table"
          :headers="inventoryTagsHeaders"
          :items="inventoryTags"
          :footer-props="inventoryTagsTableFooterProps"
          :items-per-page="inventoryTagsPerPage"
          :search="search"
          :loading="loading"
          dense
        >
         <template v-slot:[`item.unique_code`]="{ item }">
            <text-highlight :queries="search">
              {{ item.unique_code ? item.unique_code : '-' }}
            </text-highlight>
          </template>
          <template v-slot:[`item.material_id`]="{ item }">
            <text-highlight :queries="search">
              {{ item.material_id }}
            </text-highlight>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <text-highlight :queries="search">
              {{ item.name }}
            </text-highlight>
          </template>
          <template v-slot:[`item.inventory_tag`]="{ item }">
            <span style="font-weight: bold;">
              <text-highlight :queries="search">
                {{ item.inventory_tag ? item.inventory_tag : '-' }}
              </text-highlight>
            </span>
          </template>
          <template v-slot:[`item.manufacturers`]="{ item }">
              {{ item.manufacturer ? item.manufacturer : '-' }}
          </template>
          <template v-slot:[`item.material_property`]="{ item }">
              {{item.material_property ? item.material_property: '-'}}
          </template>
          <template v-slot:[`item.length`]="{ item }">
              {{ item.length ? item.length : '-' }}
          </template>
          <template v-slot:[`item.color`]="{ item }">
              {{ item.color_code ? item.color_code : '-' }}
          </template>
          <template v-slot:[`item.finishing`]="{ item }">
              {{ item.finishing ? item.finishing : '-' }}
          </template>
          <template v-slot:[`item.uom`]="{ item }">
              {{ item.uom }}
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
             {{ item.quantity }}
          </template>
          <template v-slot:[`item.location`]="{ item }">
              {{ item.location ? item.location : '-'}}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import InventoryTagsApi from '../../api/InventoryTagsApi';
import ModulesApi from '../../api/ModulesApi';
import ExcelHelper from '../../helpers/ExcelHelper';
import PdfHelper from '../../helpers/PdfHelper';
import * as FormatHelpers from '../../helpers/FormatHelpers';

export default {
  name: 'InventoryTags',
  components: {
    TextHighlight,
  },
   computed:{
    inventoryTagsHeaders(){
      let headers = []
      if(this.enabled_unique_item_code == 1){
        headers.push({text: 'Unique Item Code', value: 'unique_code', class: 'unique_item_code' })
      }
      if(this.enabled_material_id == 1){
        headers.push({ text: 'Material ID', value: 'material_id', class: 'material_id' })
      }
      headers.push({ text: 'Item Name', value: 'name' })
      headers.push({ text: 'Inventory Tag', value: 'inventory_tag' })
      headers.push({ text: 'Manufacturer', value: 'manufacturer', filterable: false })
      headers.push({ text: 'Material Property', value: 'material_property', filterable: false})
      headers.push({ text: 'Length', value: 'length', filterable: false })
      headers.push({ text: 'Colour', value: 'color', filterable: false })
      headers.push({ text: 'Finishing', value: 'finishing', filterable: false })
      headers.push({ text: 'Quantity', value: 'quantity' })
      headers.push({ text: 'UOM', value: 'uom' })
      headers.push({ text: 'Location', value: 'location', filterable: false, align: 'end' })

      return headers;
    },
  },
  data: () => ({
    // Page
    search: '',
    loading: true,
    inventoryTags: [],
   /* // inventoryTagsHeaders: [
    //   { text: 'Unique Item Code', value: 'unique_code', class: 'unique_item_code' },
    //   { text: 'Material ID', value: 'material_id', class: 'material_id' },
    //   { text: 'Item Name', value: 'name' },
    //   { text: 'Inventory Tag', value: 'inventory_tag' },
    //   { text: 'Manufacturer', value: 'manufacturer', filterable: false },
    //   { text: 'Material Property', value: 'material_property', filterable: false,},
    //   { text: 'Length', value: 'length', filterable: false },
    //   { text: 'Color', value: 'color', filterable: false },
    //   { text: 'Finishing', value: 'finishing', filterable: false },
    //   { text: 'Quantity', value: 'quantity' },
    //   { text: 'UOM', value: 'uom' },
    //   { text: 'Location', value: 'location', filterable: false, align: 'end' }
    // ],*/
    inventoryTagsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    inventoryTagsPerPage: 20,
    enabled_material_id: null,
    enabled_unique_item_code: null,
  }),
  //  watch: {
  //   search: {
  //     handler() {
  //       this.updateSearch();
  //     },
  //   },
  // },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await Promise.all([this.fetchTags(), this.fetchModules()]);
    },
    updateSearch(search) {
      this.search = search;
    },
    generateExcel() {
      const excelData = this.inventoryTags.map((el) => {
        return {
          'Unique Item Code': el.unique_code,
          'Die #': el.material_id,
          'Description': el.name,
          'Inv, Tag': el.inventory_tag,
          Manufacturer: el.manufacturer,
          'Material Property': el.material_property,
          'Stock Length': el.length,
          Color: el.color_code,
          Finishing: el.finishing,
          Quantity: el.quantity,
          UOM: el.uom,
          'Warehouse SKU#': el.location
          
        };
      });
      let excelWorkbook = ExcelHelper.writeJson(excelData, 'Inventory Tags');
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Inventory-Tags_${now}`;
      ExcelHelper.saveExcelToBrowser(excelWorkbook, fileName);
    },
    async generatePdf() {
      this.inventoryTagsPerPage = -1;
      await this.$nextTick();
      const pdf = PdfHelper.generateFromTable(
        '#inventory-tags-table > div > table',
        'landscape',
        'Inventory Tags',
        'inventory-tags'
      );
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Inventory-Tags_${now}`;
      PdfHelper.savePdfToBrowser(pdf, fileName);
    },

    async fetchTags() {
      this.loading = true;
      this.inventoryTags = await InventoryTagsApi.getList();
      this.loading = false;
    },
    async fetchModules() {
      this.modules = await ModulesApi.getList();
      this.enabled_material_id = this.modules[5].enabled;
      this.enabled_unique_item_code = this.modules[6].enabled;
      if(this.enabled_material_id == 0) {
        if(document.getElementsByClassName('material_id')) document.getElementsByClassName('material_id')[0].style.display = 'none';
      } 
      if(this.enabled_unique_item_code == 0) {
        if(document.getElementsByClassName('unique_item_code')) document.getElementsByClassName('unique_item_code')[0].style.display = 'none';
      } 
    }
  },
};
</script>
<style scoped>

.text-bold{
  background: red;
}

</style>
