var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"inventory-tags-table","headers":_vm.inventoryTagsHeaders,"items":_vm.inventoryTags,"footer-props":_vm.inventoryTagsTableFooterProps,"items-per-page":_vm.inventoryTagsPerPage,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.unique_code",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.unique_code ? item.unique_code : '-')+" ")])]}},{key:"item.material_id",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.material_id)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.inventory_tag",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.inventory_tag ? item.inventory_tag : '-')+" ")])],1)]}},{key:"item.manufacturers",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.manufacturer ? item.manufacturer : '-')+" ")]}},{key:"item.material_property",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.material_property ? item.material_property: '-')+" ")]}},{key:"item.length",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.length ? item.length : '-')+" ")]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.color_code ? item.color_code : '-')+" ")]}},{key:"item.finishing",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.finishing ? item.finishing : '-')+" ")]}},{key:"item.uom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.uom)+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" ")]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.location ? item.location : '-')+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }